import { Component, OnInit, Input } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { OrdersClient, BMFeedback, BMFeedbackType, BMOrder } from '../../api/clients'
import { NavParams} from '@ionic/angular';
import { UtilsService } from '../../api/utils.service';

@Component({
  selector: 'payment-modal-page',
  templateUrl: './payment.dialog.html',  
})
export class PaymentModalPage implements OnInit {

	amount: any;
  orderNumber: any;
  orderComment: any;
  profile: any;

  constructor(
    public utils: UtilsService,
    public navParams : NavParams, 
    public modalController: ModalController, 
    public api: OrdersClient) 
  { 
  }

  ngOnInit(){
    this.amount = this.navParams.get('amount');
    console.log(this.amount);
    this.profile = this.utils.profile();
    console.log(this.profile);
    this.orderNumber = Date.now();
  }


  close() {
    // using the injected ModalController this page
    // can "dismiss" itself and optionally pass back data
    this.modalController.dismiss(null);
  }

  send() {
    document['form1'].submit();
  }

}

@Component({
  selector: 'add-money-page',
  templateUrl: './add.money.html',  
})
export class AddMoneyModalPage extends PaymentModalPage {}
