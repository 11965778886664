import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { OrdersClient, BMOrder, TeamsClient, BMTeam, BMEventDTO } from '../api/clients'
import { EnumsService } from '../api/enums.service';
import { AlertController } from '@ionic/angular';
import { ModalController } from '@ionic/angular';
import { BaseComponent } from '../base/base.component';
import { ODataClient } from '../api/odata.client';
import { SignalRService } from '../api/signalr.service';
import { LoadingController } from '@ionic/angular';

@Component({
  selector: 'app-order-details',
  templateUrl: './order-details.page.html',
  styleUrls: ['./order-details.page.scss'],
})
export class OrderDetailsPage extends BaseComponent implements OnInit {

	id : string;
	order : BMOrder;
  offers: BigInteger;


  constructor(
    private signal: SignalRService,
  	private enums : EnumsService, 
  	private api: OrdersClient, 
    private teamapi: TeamsClient,
    private odata: ODataClient<BMEventDTO>,
    private alertController: AlertController,
    private modalController: ModalController,
  	private route: ActivatedRoute,
    private loadingController: LoadingController
  	) { 
      super();
    }


  ngOnInit() {
    this.signal.newEvent.subscribe(e=>{
      console.log(e);
      if (e.OrderId == this.order.id)
        this.refreshOrder();
    });
  	return this.route.params.subscribe(params=>{
  		return this.api.get(params.id).subscribe(order=>{
  			this.order = order;      
  		})
  	})
  }

  refreshOrder(){
    console.log('refresh order')
    this.api.get(this.order.id).subscribe((order)=>{
      this.order = order;
      if (order.teamId)
        this.teamapi.get(order.teamId).subscribe((team)=>{
          this.order.team = team;
        })
    });
  }  



  async start() {
    const loading = await this.loadingController.create();
    loading.present();

    this.api.start(this.order.id).subscribe(data => {
      this.showMessage("Внимание", "Вы подтвердили завершение загрузки!");
      loading.dismiss();
    }, (err) => loading.dismiss() );

  }  


  async finish() {
    const loading = await this.loadingController.create();
    loading.present();

    this.api.finish(this.order.id).subscribe(data => {
      this.showMessage("Внимание", "Вы подтвердили завершение доставки груза!");
      loading.dismiss();
      this.refreshOrder();      
    }, (err) => loading.dismiss() );

  }  


  async accept(){
    const loading = await this.loadingController.create();
    loading.present();

    this.api.doeraccept(this.order.id).subscribe(data => {
      console.log(data);
      this.showMessage("Внимание", "Заказ принят!");
      loading.dismiss();
      this.refreshOrder();
    }, (err) => loading.dismiss() );
  
  }

}
