import { Injectable } from '@angular/core';
import { FeedbackModalPage } from './modals/feedback.modal';
import { AcceptOfferModalPage } from './modals/accept.offer.modal';
import { PaymentModalPage, AddMoneyModalPage } from './modals/payment.modal';
import { ChatModalPage } from './modals/chat.modal';
import { EnterCodeModalPage } from './modals/enter.code.page';
import { ModalController } from '@ionic/angular';

@Injectable({
  providedIn: 'root'
})
export class ModalsService {

  constructor(private modalController : ModalController) { }

  async feedbackFlow(order) {
	  const modal = await this.modalController.create({
	    component: FeedbackModalPage,
	   	componentProps: {
	      'order': order
	    }
	  });
	  return await modal.present();
  }

  async acceptOfferFlow(offer, complete: (data) => any){
	  const modal = await this.modalController.create({
	    component: AcceptOfferModalPage,
	   	componentProps: {
	      'offer': offer
	    }
	  });

	  modal.onDidDismiss().then(data=>{
	  	if (complete) complete(data);
	  })

	  return await modal.present();  	
  }

  async chatFlow(offer){
	  const modal = await this.modalController.create({
	    component: ChatModalPage,
	   	componentProps: {
	      'offer': offer
	    }
	  });
	  return await modal.present();  	
  }  

  async paymentFlow(amount){
  	const modal = await this.modalController.create({
	    component: PaymentModalPage,
	    componentProps: {
	      'amount': amount
	    }
	  });
	  return await modal.present();  	
  }

   async addMoneyFlow(){
  	const modal = await this.modalController.create({
	    component: AddMoneyModalPage,
	    componentProps: {
	      'amount': 1000
	    }
	  });
	  return await modal.present();  	
  }

  async enterCode(action: (code)=>any){
  	const modal = await this.modalController.create({
	    component: EnterCodeModalPage,
	  });
	  modal.onDidDismiss().then(data=>{
	  	if (action) action(data);
	  })	  
	  return await modal.present();  	
  }
}
