// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  firebase: {
	  apiKey: "AIzaSyDQ0cGuEk5WvfeDuvpE7twmpnLkFb7-weI",
	  authDomain: "bizon-service.firebaseapp.com",
	  databaseURL: "https://bizon-service.firebaseio.com",
	  projectId: "bizon-service",
	  storageBucket: "bizon-service.appspot.com",
	  messagingSenderId: "1062800969211",
	  appId: "1:1062800969211:web:a8f824af09eaa40450cf5e",
	  vapidKey: "BAZ71XLSMn2hhM4KHa-5B7ErO5aOsJmmPllPAmPta2fHW3nVXYKWqFyUB3MfIAiZAqIOoMxSUPHoE28zW1qSiC0"
  }  
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
