import { Component, OnInit, Input } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { BaseComponent } from '../../base/base.component';
import { OrdersClient, BMFeedback, BMFeedbackType, BMOrder } from '../../api/clients';
import { NavParams} from '@ionic/angular';
import { UtilsService } from '../../api/utils.service';

@Component({
  selector: 'enter-code-page',
  templateUrl: './enter.code.html'  
})
export class EnterCodeModalPage extends BaseComponent implements OnInit {

	code: any;

  constructor(
    public utils: UtilsService,
    public navParams : NavParams, 
    public modalController: ModalController, 
    public api: OrdersClient) 
  { 
    super();
  }

  ngOnInit(){
    
  }


  close() {
    // using the injected ModalController this page
    // can "dismiss" itself and optionally pass back data
    this.modalController.dismiss(null);
  }

  accept() {
    console.log(this.code);
    this.modalController.dismiss(this.code);
  }

}

