import { Component, OnInit, Input } from '@angular/core';
import { ModalController, LoadingController } from '@ionic/angular';
import { OrdersClient, BMFeedback, BMFeedbackType, BMOrder } from '../../api/clients'
import { NavParams} from '@ionic/angular';
import { UtilsService } from '../../api/utils.service';
import { BaseComponent } from '../../base/base.component';


@Component({
  selector: 'feedback-modal-page',
  templateUrl: './feedback.dialog.html',  
})
export class FeedbackModalPage extends BaseComponent implements OnInit {

	order: any;
  feedback: BMFeedback;

  constructor(
    public utils: UtilsService,
    public navParams : NavParams, 
    public modalController: ModalController, 
    public loadingController: LoadingController,
    public api: OrdersClient) 
  { 
    super();
  }

  ngOnInit(){
    this.order = this.navParams.get('order');
    this.feedback = new BMFeedback();
    console.log(this.order);
    this.feedback.rating = 5;
  }


  close() {
    // using the injected ModalController this page
    // can "dismiss" itself and optionally pass back data
    this.modalController.dismiss(null);
  }

  async send() {
    const loading = await this.loadingController.create();
    loading.present();

    console.log(this);
    this.feedback.orderId = this.order.Id;
    this.feedback.fromUserId = this.utils.userId();
    this.feedback.type = BMFeedbackType.ClientFeedback;

    console.log(this.feedback);

    this.api.clientfeedback(this.order.Id, this.feedback).subscribe((data)=>{
      console.log(data);
      this.modalController.dismiss(data);
      this.showMessage("Отлично", "Ваш отзыв успешно отправлен!");
      loading.dismiss();
    }, (err) => loading.dismiss() );
  }

}