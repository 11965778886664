import { Component, OnInit, ViewChild, ElementRef, Input } from '@angular/core';

declare var H: any;

@Component({
    selector: 'here-map',
    templateUrl: './here-map.component.html',
    styleUrls: ['./here-map.component.scss']
})
export class HereMapComponent implements OnInit {

    @ViewChild("map")
    public mapElement: ElementRef;

    map: any;
    platform: any;

    @Input()
    public lat: any;

    @Input()
    public lng: any;

    @Input()
    public start: any;

    @Input()
    public finish: any;

    @Input()
    public width: any;

    @Input()
    public height: any;

    public constructor() { }

    public ngOnInit() { 
        console.log(this.start);
    }

    public ngAfterViewInit() {
        this.platform = new H.service.Platform({
            "apikey": 'fBJ5intqh2RurxszBQ1doLSnmG-rs14Dd6ZrruA3gFw'
        });
        let defaultLayers = this.platform.createDefaultLayers();
        this.map = new H.Map(
            this.mapElement.nativeElement,
            defaultLayers.vector.normal.map,
            {
                zoom: 14,
                center: { lat: this.lat, lng: this.lng }
            }
        );
        this.map.getViewPort().padding = { left:20, right: 20, top: 20, bottom: 20 };

        this.route();
    }

    route(){
        console.log("route")
        if (!this.start) return;
        if (!this.finish) return;

        let mapObjects = new H.map.Group()

        var iconStart = new H.map.Icon('../../assets/images/red-circle.png');
        var startPointMarker = new H.map.Marker({ lat: this.start.lat, lng: this.start.lng }, { icon: iconStart });

        var iconEnd = new H.map.Icon('../../assets/images/green-circle.png');
        var endPointMarker = new H.map.Marker({ lat: this.finish.lat, lng: this.finish.lng }, { icon: iconEnd });

        // Create the parameters for the routing request:
        var routingParameters = {
          'mode': 'balanced;truck',
          // The start point of the route:
          'waypoint0': 'geo!' + this.start.lat + ',' + this.start.lng,
          // The end point of the route:
          'waypoint1': 'geo!' + this.finish.lat + ',' + this.finish.lng,
          // To retrieve the shape of the route we choose the route
          // representation mode 'display'
          'representation': 'display',
          'routeAttributes': 'summary'
        };

        // Define a callback function to process the routing response:
        let _map = this.map;
        var onResult = function(result) {
          var route,
          routeShape,
          linestring;

          console.log(result);

          if (result.response.route) {

            // Pick the first route from the response:
            route = result.response.route[0];
            // Pick the route's shape:
            routeShape = route.shape;

            // Create a linestring to use as a point source for the route line
            linestring = new H.geo.LineString();

            // Push all the points in the shape into the linestring:
            routeShape.forEach(function(point) {
              var parts = point.split(',');
              linestring.pushLatLngAlt(parts[0], parts[1]);
            });

            // Create a polyline to display the route:
            var routeLine = new H.map.Polyline(linestring, {
              style: { strokeColor: 'green', lineWidth: 3 }
            });


            // Add the route polyline and the two markers to the map:
            _map.addObjects([routeLine, startPointMarker,endPointMarker]);
            let mapObjects = [routeLine, startPointMarker,endPointMarker];

            // Set the map's viewport to make the whole route visible:
            _map.getViewModel().setLookAtData(
              {bounds: routeLine.getBoundingBox()}
              );
          }
        };

        // Get an instance of the routing service:
        var router = this.platform.getRoutingService();

        // Call calculateRoute() with the routing parameters,
        // the callback and an error callback function (called if a
        // communication error occurs):
        router.calculateRoute(routingParameters, onResult,
          function(error) {
            alert(error.message);
          });
      }

}