import { Injectable } from '@angular/core';
import { Router, CanActivate } from '@angular/router';
import { UtilsService } from './utils.service';

@Injectable()
export class AuthGuardService implements CanActivate {
  constructor(public utils: UtilsService, public router: Router) {}
  canActivate(): boolean {
  	var isAuth = this.utils.isAuthenticated()
  	console.log(isAuth);
    if (!isAuth) {
      this.router.navigate(['login']);
      return false;
    }
    return true;
  }
}