import { Component, OnInit, Input } from '@angular/core';
import { ModalController, LoadingController } from '@ionic/angular';
import { OrdersClient, BMFeedback, BMFeedbackType, BMOrder } from '../../api/clients'
import { NavParams} from '@ionic/angular';
import { UtilsService } from '../../api/utils.service';
import { BaseComponent } from '../../base/base.component';

@Component({
  selector: 'accept-offer-modal-page',
  templateUrl: './accept-offer.dialog.html',  
})
export class AcceptOfferModalPage extends BaseComponent implements OnInit {

	offer: any;
  cash: boolean;

  constructor(
    public utils: UtilsService,
    public navParams : NavParams, 
    public modalController: ModalController, 
    public loadingController: LoadingController,
    public api: OrdersClient) 
  { 
    super();
  }

  ngOnInit(){

    this.offer = this.navParams.get('offer');
    this.cash = false;
  }


  close() {
    // using the injected ModalController this page
    // can "dismiss" itself and optionally pass back data
    this.modalController.dismiss(null);
  }

  async accept() {
    const loading = await this.loadingController.create();
    loading.present();

    this.api.clientaccept(this.offer.OrderId, this.offer.UserId, this.cash ).subscribe( data => {
      this.showMessage( "Да!", "Предложение успешно принято!");
      loading.dismiss();
      this.modalController.dismiss(data);        
    }, (err) => loading.dismiss() );
  }

}