import { NgModule  } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { IonicModule } from '@ionic/angular';

import { SearchModalPage, CountryRegionComponent } from './country-region/country-region.component';
import { HereMapComponent } from './here-map/here-map.component';
import { OrderInfoComponent } from './order-info/order-info.component';
import { FeedbackModalPage } from './modals/feedback.modal';
import { AcceptOfferModalPage } from './modals/accept.offer.modal';
import { ChatModalPage } from './modals/chat.modal';
import { PaymentModalPage } from './modals/payment.modal';
import { EnterCodeModalPage } from './modals/enter.code.page';


const COMPONENTS = [
	SearchModalPage,
	CountryRegionComponent,
  FeedbackModalPage,
  AcceptOfferModalPage,
  ChatModalPage,
  PaymentModalPage,
  EnterCodeModalPage,
  HereMapComponent, 
  OrderInfoComponent
];

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,        
    IonicModule
  ],
  declarations: COMPONENTS,
  exports: COMPONENTS
})
export class ComponentsModule {}