import { Injectable, EventEmitter, Output, OnInit, NgZone } from '@angular/core';
import {environment} from '../../environments/environment'
import {UtilsService} from './utils.service';
import 'signalr';
declare var $:JQueryStatic;


@Injectable({
  providedIn: 'root'
})
export class SignalRService implements OnInit {

	constructor(private utils: UtilsService, private zone: NgZone)
	{}

	ngOnInit() {}
    
  connection: any;
  hub: any;

	@Output() 
	newEvent: EventEmitter<any> = new EventEmitter<any>();

	init(){
		this.connection = $.hubConnection('https://api.bisone.ru:2100/signalr');
		this.connection.stateChanged(function (change) {
		    if (change.newState === $.signalR.connectionState.reconnecting) {
		        console.log('Re-connecting');
		    }
		    else if (change.newState === $.signalR.connectionState.connected) {
		        console.log('The server is online');
		    } 
		});	
		this.connection.reconnected(function() {
		    console.log('Reconnected');
		});	
		this.connection.start(()=>{
			console.log('connection started');
  			this.join(this.utils.userId());			
		});

		this.hub = this.connection.createHubProxy("projecthub");
		this.hub.on("NewEvent", (e) => {
			this.emitNewEvent(e);
		});		
		console.log(this.hub);
	}

	async emitNewEvent(e){
		this.zone.run(async () => {
			console.log(e);
			await this.newEvent.emit(e);						
		})
	}

	join(id){
		this.hub.invoke('JoinUser', id ).done(function () {
	        console.log ('Invocation of JoinUser succeeded ' + id);
	    }).fail(function (error) {
	        console.log('Invocation of JoinUser failed. Error: ' + error);
	    });		
	}

	unjoin(id){
		this.hub.invoke('UnjoinUser', id ).done(function () {
	        console.log ('Invocation of UnjoinUser succeeded');
	    }).fail(function (error) {
	        console.log('Invocation of UnjoinUser failed. Error: ' + error);
	    });		
	}

}
