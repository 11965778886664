import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, FormControl, Validators } from '@angular/forms';
import { AlertController, LoadingController } from '@ionic/angular';
import { Router } from '@angular/router';
import { BMRegisterUser, BMRegisterUserType, UsersClient } from '../api/clients';
import { MaskPipe } from 'ngx-mask';

@Component({
  selector: 'app-forgot',
  templateUrl: './forgot.page.html',
  styleUrls: ['./forgot.page.scss'],
})
export class ForgotPage implements OnInit {

  fields: FormGroup;
  codeSended : boolean = false;

  constructor(
    private maskPipe: MaskPipe,
    private fb: FormBuilder,
    private api: UsersClient,
    private alertController: AlertController,
    private router: Router,
    private loadingController: LoadingController
  ) {}

  ngOnInit() {
    this.fields = this.fb.group({
      phone: ['', [Validators.required, Validators.pattern('[- +()0-9]+')]],
      code: ['', [Validators.required, Validators.minLength(4)]]
    });
  }

    // Easy access for form fields
  get phone() {
    return this.fields.get('phone');
  }
  
  get code() {
    return this.fields.get('code');
  }

	updateWithMask(event) {  
		this.fields.controls.phone.setValue(this.maskPipe.transform(event.currentTarget.value, '+0 (000) 000-00-00'));
	}

	sendCode(){
		this.api.forgotcode(this.fields.value.phone).subscribe((data)=>{			
			this.codeSended = true;			
		});
	}

	forgot(){
		this.api.forgotpsw(this.fields.value.phone, this.fields.value.code).subscribe(data=>{
			this.router.navigate(['/login']);
		})
	}
}
