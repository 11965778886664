import { Component, OnInit, Input, ViewChild } from '@angular/core';
import { ModalController, IonContent } from '@ionic/angular';
import { OrdersClient, BMChatMessage, BMOrder, BMEventDTO } from '../../api/clients'
import { ODataClient } from '../../api/odata.client';
import { NavParams} from '@ionic/angular';
import { UtilsService } from '../../api/utils.service';
import { SignalRService } from '../../api/signalr.service';

@Component({
  selector: 'chat-modal-page',
  templateUrl: './chat.dialog.html',  
  styleUrls: ['./chat.dialog.scss']
})
export class ChatModalPage implements OnInit {

  offer: any;
  messages: any[];
  newmsg: BMChatMessage;
  myUserId: string;

  @ViewChild(IonContent, { static: true }) ionContent: IonContent;  

  constructor(
    public signalr : SignalRService,
    public utils: UtilsService,
    public navParams : NavParams, 
    public modalController: ModalController, 
    public odata : ODataClient<BMEventDTO>,
    public api: OrdersClient) 
  {     
  }

  ngOnInit(){
    this.signalr.newEvent.subscribe((e)=>{
      console.log(e);
      if (e.Class == 'Chat' && e.RoomId == this.offer.RoomId)
      {        
        this.messages.push(e);
      }
      return;
    });
    this.offer = this.navParams.get('offer');    
    this.newmsg = new BMChatMessage();
    this.myUserId = this.utils.userId();
    this.load();

    console.log(this.offer);
  }


  close() {
    this.modalController.dismiss(null);
  }

  send() {
    this.newmsg.to = this.offer.TeamId;
    this.api.message(this.offer.OrderId, this.newmsg).subscribe((msg) => {
      this.newmsg = new BMChatMessage();
      this.messages.push(msg);
      this.ionContent.scrollToBottom();      
    })
  }

  load() { 
    this.odata.get('/odata/odtEvents?Classes=Chat&$orderby=DT&$expand=User($select=Name),Assets($expand=Assets)&orderId=' 
      + this.offer.OrderId + '+&TeamId=' + this.offer.TeamId ).subscribe(data => {
        this.messages = data.value;
        this.ionContent.scrollToBottom();
      })
  }

}