import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { AddressesClient, BAddress } from '../../api/clients'
import { } from 'google-maps'

@Component({
  selector: 'app-country-region',
  templateUrl: './country-region.component.html',
  styleUrls: ['./country-region.component.scss'],
})
export class CountryRegionComponent implements OnInit {

  @Input() item : BAddress;
  searchText : string;

  @Output() 
  addressChanged: EventEmitter<any> = new EventEmitter<any>();


  constructor(public modalController: ModalController) { }

  ngOnInit() {

  }

  async searchModal() {
	  const modal = await this.modalController.create({
	    component: SearchModalPage,
	    cssClass: 'my-custom-class',
	    componentProps: {
	      'searchText': this.searchText
	    }
	  });

	  modal.onDidDismiss()
	    .then((data) => {
        console.log('save address')
        var newAddress = data.data as BAddress;
        console.log(newAddress);
        Object.keys(newAddress).forEach( (key) => this.item[key] = newAddress[key] );
        console.log(this.item);
        this.addressChanged.emit(this.item);
	  	});

	  return await modal.present();
  }

  dismiss() {
    // using the injected ModalController this page
    // can "dismiss" itself and optionally pass back data
    this.modalController.dismiss({
      'dismissed': true
    });
  }

}

@Component({
  selector: 'search-modal-page',
  templateUrl: './country-region.dialog.html',  
})
export class SearchModalPage {

	items : Array<any>;

  constructor(public modalController: ModalController, public api: AddressesClient) { }

  find(text){
    if (!text || text.length < 3) return;
  	console.log(text);
  	return this.api.predictions(text, null, null).subscribe((data) => {
  		this.items = data;
  	}, (error) => {

  	});
  }

  itemDidSelected(item) {

  	this.api.location(item.locationId).subscribe((data) =>{
      var address = new BAddress(); 
      address.init(data);
      console.log(address);
    	this.modalController.dismiss( address );
  	});

  }

  close() {
    // using the injected ModalController this page
    // can "dismiss" itself and optionally pass back data
    this.modalController.dismiss(null);
  }

}