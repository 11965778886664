import { Component, OnInit } from '@angular/core';
import { ToastController } from '@ionic/angular';
import { AppInjector } from '../base/app.injector';
import { Router } from '@angular/router';
import { UsersClient } from '../api/clients';


@Component({
  selector: 'app-base',
  templateUrl: './base.component.html',
  styleUrls: ['./base.component.scss'],
})
export class BaseComponent {

  toast : ToastController;
  router : Router;

  constructor() { 
    this.toast = AppInjector.injector.get(ToastController);
    this.router = AppInjector.injector.get(Router);
  }

  public showMessage(header: string, text: string){
    this.toast.create({
      header: header,
      message: text,
      duration: 3000,
      position: 'bottom'
    }).then((toast) => {
      toast.present();
    });
  }

  public showError(){
    this.showMessage("Ошибка!", "Что-то пошло не так, попробуйте чуть позже еще раз." );
  }

  public navigate( path: string ) {
    this.router.navigate([path]);
  }

}
