import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { OrdersClient, BMEventDTO } from '../api/clients';
import { ODataClient } from '../api/odata.client';
import { EnumsService } from '../api/enums.service';
import { AlertController } from '@ionic/angular';
import { BaseComponent } from '../base/base.component';
import { ModalsService } from '../components/modals.service';

@Component({
  selector: 'app-order-offers',
  templateUrl: './offers.page.html',
  styleUrls: ['./offers.page.scss'],
})
export class OffersPageComponent extends BaseComponent implements OnInit {

	id : string;  
  baseUrl : string;
  items : BMEventDTO[];
  order : any;

  constructor(
    private api : OrdersClient,
    private modals : ModalsService,
  	private enums : EnumsService, 
  	private odata: ODataClient<BMEventDTO>, 
    private ordersapi: OrdersClient,
    private alertController: AlertController,
  	private route: ActivatedRoute
  	) { 
      super();
    }


  ngOnInit() {
    this.route.params.subscribe((params) => {
      this.baseUrl = '/odata/odtEvents?Classes=Offer&$expand=Team($select=Name),User($expand=Contact($select=Address);$select=Name,Contact,ClientRating),Order($select=State)&orderId='+ 
        params.id;      
      this.api.get(params.id).subscribe(order => { 
        this.order = order;
        this.loadData(null);      
      });
    })
  }

  loadData(event){
    this.odata.get(this.baseUrl + '&$top=20&$orderby=Created desc')
      .subscribe( (data) => {
          this.items = data.value;
          if (event) event.target.complete();
      });
  }

  loadMoreData(event){
    return this.odata.get(this.baseUrl + '&$top=20&$orderby=Created desc&$skip=' + this.items.length )
      .subscribe( (data) => {
          this.items = this.items.concat(data.value);
          if (event) event.target.complete();
      });
  }

  async showDialog(item){
    console.log(item);    
    await this.modals.chatFlow(item);
  }

  async acceptOffer(item) {
    await this.modals.acceptOfferFlow(item, (data) => {
      if (data != null)
        this.router.navigate(['../']);
    });

/*       const alert = await this.alertController.create({
        header: 'Отмена заказа',
        message: 'Желаете отменить этот заказ?',
      buttons: [
        {
          text: 'Да',
          role: 'yes',
          cssClass: 'secondary',
          handler: (blah) => {
            this.ordersapi.clientaccept(this.id, item.Id, cash).subscribe( res =>{
               this.showMessage("", "Предложение принято!")
            })
          }
        }, {
          text: 'Нет',
          role: 'no',
          handler: () => {

          }
        }
      ]
      });

      await alert.present();*/
  }
}
