import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { SignalRService } from './api/signalr.service';
import { UtilsService } from './api/utils.service';
import { EnumsService } from './api/enums.service';
import { Platform } from '@ionic/angular';
import { AlertController, LoadingController } from '@ionic/angular';
import { BaseComponent } from './base/base.component';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent extends BaseComponent implements OnInit {
  constructor(
  	private hs: SignalRService, 
  	private utils: UtilsService,
  	private enums: EnumsService,
    private alertController: AlertController,    
    private loadingController: LoadingController,
  	private platform : Platform ){ super(); }

	async ngOnInit() {

		this.hs.init();		
		this.utils.init();
		this.enums.init();

    if (this.utils.isAuthenticated())    
      await this.loadProfile();

    this.hs.newEvent.subscribe(e=>{
      if (e.Class != 'Chat')
        this.showMessage(e.Header, e.Text);
      this.utils.loadProfile();
      this.utils.beep();
    })
	}


  async loadProfile(){
    const loading = await this.loadingController.create();
    await loading.present();

    this.utils.loadProfile().subscribe(async data=>{
        
        await loading.dismiss();    

        this.utils.save('profile', data);
    
        //this.router.navigateByUrl('', { replaceUrl: true });          
      }, async (res) => {

        await loading.dismiss();         

        const alert = await this.alertController.create({
          header: 'Что-то пошло не так!',
          message: "Не удалось загрузить профиль пользователя.",
          buttons: ['OK'],
        });
 
        await alert.present();          
      })
  }
}
