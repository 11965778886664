import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http'

export class Enums {
	constructor(obj?: any) {
	    Object.assign(this, obj);
	}	
    moderationStatuses: EnumClass[];
    teamTypes:          EnumClass[];
    userTypes:          EnumClass[];
    userStatuses:       EnumClass[];
    orderStates:        EnumClass[];
    eventTypes:         EnumClass[];
    eventClasses:       EnumClass[];
}

export class EnumClass {
	constructor(obj?: any) {
	    Object.assign(this, obj);
	}	
    id:         number;
    name:       string;
    text:       string;
    styleWeb:   string;
}

@Injectable({
  providedIn: 'root'
})
export class EnumsService {
  
  data : Enums;

  constructor(private http: HttpClient) { }

  init() {
		return this.http.get<Enums>('https://api.bisone.ru:2100/v1/api/enum/list')
			.subscribe((data) => {
				this.data = data;
				console.log(this.data);
			})
  }

  orderState(state){
  	return this.data.orderStates.find((s)=>{ return s.id == state || s.name == state })?.text;
  }

  eventType(type){
    return this.data.eventTypes.find((s) => { return s.id == type || s.name == type })?.text;
  }
}
