import { Component, OnInit } from '@angular/core';
import { UsersClient } from '../api/clients';
import { UtilsService } from '../api/utils.service';
import { BaseComponent } from '../base/base.component';
import { AlertController } from '@ionic/angular';
import { ModalsService } from '../components/modals.service';


@Component({
  selector: 'app-profile',
  templateUrl: './profile.page.html',
  styleUrls: ['./profile.page.scss'],
})
export class ProfilePage extends BaseComponent implements OnInit {

	profile: any;

  constructor(
  	private utils: UtilsService, 
  	private alertController: AlertController,
  	private modals : ModalsService
  	) 
  { super(); }

  ngOnInit() {
  	this.profile = this.utils.profile();
  }

  async logout() {

    const alert = await this.alertController.create({
      header: 'Подтверждение',
      message: 'Желаете выйти из системы?',
      buttons: [
        {
          text: 'Отмена',
          role: 'cancel'
        }, {
          text: 'Да, выйти',
          handler: () => {
				  	this.utils.clearAuth();
				  	this.showMessage("Готово", "Выполнен выход из системы!");
				  	(window as any).location = '/';
          }
        }
      ]
    });

    await alert.present();
  }

  async addMoney(){
  	await this.modals.addMoneyFlow();
  }

}
