import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, FormControl, Validators } from '@angular/forms';
import { AlertController, LoadingController } from '@ionic/angular';
import { Router } from '@angular/router';
import { UsersClient } from '../api/clients';
import { UtilsService } from '../api/utils.service';
import { MaskPipe } from 'ngx-mask';

@Component({
  selector: 'app-login',
  templateUrl: './login.page.html',
  styleUrls: ['./login.page.scss'],
})
export class LoginPage implements OnInit {

  fields: FormGroup;
 
  constructor(
  	private maskPipe: MaskPipe,
    private fb: FormBuilder,
    private api: UsersClient,
    private utils: UtilsService,
    private alertController: AlertController,
    private router: Router,
    private loadingController: LoadingController
  ) {}
 
  ngOnInit() {
    this.fields = this.fb.group({
      phone: ['', [Validators.required, Validators.pattern('[- +()0-9]+')]],
      password: ['', [Validators.required, Validators.minLength(6)]]
    });
    console.log(this.fields);
  }
 
  async login() {
    const loading = await this.loadingController.create();
    await loading.present();
    
    this.api.token(this.fields.value.phone, this.fields.value.password).subscribe(
      async (res) => {
      	this.utils.saveAuth(res);
        this.utils.loadProfile().subscribe(async data=>{
          this.utils.save('profile', data);
          await loading.dismiss();        
          this.router.navigateByUrl('/tabs/myorders', { replaceUrl: true });          
        }, async (res) => {
          await loading.dismiss();
          const alert = await this.alertController.create({
            header: 'Что-то пошло не так!',
            message: "Не удалось загрузить профиль пользователя.",
            buttons: ['OK'],
          });
   
          await alert.present();          
        })
      },
      async (res) => {
        await loading.dismiss();
        const alert = await this.alertController.create({
          header: 'Что-то пошло не так!',
          message: "Возможно номер телефона или пароль введены неверно.",
          buttons: ['OK'],
        });
 
        await alert.present();
      }
    );
  }
 
  // Easy access for form fields
  get phone() {
    return this.fields.get('phone');
  }
  
  get password() {
    return this.fields.get('password');
  }

	updateWithMask(event) {  
		this.fields.controls.phone.setValue(this.maskPipe.transform(event.currentTarget.value, '+0 (000) 000-00-00'));
	}
}
