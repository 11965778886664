import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { ProfilePage } from './profile.page';
import { SettingsPage } from '../settings/settings.page';
import { MainSettingsPage } from '../settings/main.settings.page';
import { NotificationsSettingsPage } from '../settings/notifications.page';
import { ContactsSettingsPage } from '../settings/contacts.page';
import { PaymentsPage } from '../payments/payments.page';

const routes: Routes = [
  {
    path: 'view',
    component: ProfilePage, 
  }, 
  {
    path: 'settings',
    loadChildren: () => import('../settings/settings.module').then(m => m.SettingsPageModule)
  },
  {
    path: '',
    redirectTo: 'view',
    pathMatch: 'full'
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class ProfilePageRoutingModule {}
