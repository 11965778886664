import { Component, Input, OnInit } from '@angular/core';
import { LoadingController } from '@ionic/angular';
import { BMOrder, OrdersClient, BMEventDTO } from '../../api/clients';
import { UtilsService } from '../../api/utils.service';
import { EnumsService } from '../../api/enums.service';
import { BaseComponent } from '../../base/base.component';
import { ModalsService } from '../../components/modals.service';
import { SignalRService } from '../../api/signalr.service';
import { ODataClient } from '../../api/odata.client';

@Component({
  selector: 'app-order-info',
  templateUrl: './order-info.component.html',
  styleUrls: ['./order-info.component.scss'],
})
export class OrderInfoComponent extends BaseComponent implements OnInit {

  @Input()
  order: BMOrder;

  constructor(
    private loadingController: LoadingController,
    private signal: SignalRService,
    private modals: ModalsService,
    private api: OrdersClient,
    private odata: ODataClient<BMEventDTO>,    
    private utils: UtilsService, 
    private enums: EnumsService) 
  { 
    super();
  }

  ngOnInit() {

  }

  async safeDeal() {
    var balance = this.utils.profile().Balance;

    if (this.order.price > balance) {
      await this.modals.paymentFlow(this.order.price - balance);
    }
    else {
      const loading = await this.loadingController.create();
      loading.present();
      this.api.paymentcode().subscribe( data => {
        this.showMessage("Внимание", "На Ваш телефон выслан код для подтверждения безопасной сделки.");
        loading.dismiss();        
        this.enterCode();         
      }, (error)=>{
        loading.dismiss();        
        this.enterCode();
      });
    }
  }

  async enterCode(){
     this.modals.enterCode( async data => {
        const loading = await this.loadingController.create();
        loading.present();

       console.log(data);
       this.api.payment(this.order.id, data.data).subscribe(data => {         
         this.showMessage("Внимание", "Оплата подтверждена!");             
         loading.dismiss();
       }, (err) => loading.dismiss() );
     })    

  }

  async approve() {
    const loading = await this.loadingController.create();
    loading.present();

    this.api.deliveryack(this.order.id).subscribe(data => {
      this.showMessage("Внимание", "Вы подтвердили принятие заказа!");
      loading.dismiss();
    }, (err) => loading.dismiss() );

  }

  async start() {
    const loading = await this.loadingController.create();
    loading.present();

    this.api.start(this.order.id).subscribe(data => {
      this.showMessage("Внимание", "Вы подтвердили прием груза!");
      loading.dismiss();
    }, (err) => loading.dismiss() );

  }  

  async sendFeedback() {
    var order = {};
    this.order.toJSON(order);
    await this.modals.feedbackFlow(order);
    this.router.navigate(['.']);
  }

  async showClientDialog() {

      var baseUrl = '/odata/odtEvents?Classes=Offer&$expand=Team($select=Name),User($expand=Contact($select=Address);$select=Name,Contact,ClientRating),Order($select=State)&orderId='+ 
        this.order.id + '&teamId=' + this.order.teamId;      

        this.odata.get(baseUrl)
          .subscribe( async (data) => {
              var offer = data.value[0];
              console.log(offer);
              await this.modals.chatFlow(offer);              
          }); 
  }


}
