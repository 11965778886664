import {Injectable} from '@angular/core';
import {
    HttpInterceptor,
    HttpRequest,
    HttpResponse,
    HttpHandler,
    HttpEvent,
    HttpErrorResponse
} from '@angular/common/http';

import { Observable, throwError, from } from 'rxjs';
import { map, catchError, switchMap } from 'rxjs/operators';

import { AlertController } from '@ionic/angular';

@Injectable()
export class TokenInterceptor implements HttpInterceptor {

    constructor(private alertController: AlertController) {}

    urlsToNotUse : string[] = [
      'Users/token.+',
      'Users/profile'      
    ];

    private isValidRequestForInterceptor(requestUrl: string): boolean {
        let positionIndicator: string = 'api/';
        let position = requestUrl.indexOf(positionIndicator);
        if (position > 0) {
          let destination: string = requestUrl.substr(position + positionIndicator.length);
          for (let address of this.urlsToNotUse) {
            if (new RegExp(address).test(destination)) {
              return false;
            }
          }
        }
        return true;
    }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

        var json = localStorage.getItem("auth");                
        var token = json != null ? JSON.parse(json)['access_token'] : null;

        if (token) {
            request = request.clone({ headers: request.headers.set('Authorization', 'Bearer ' + token) });
        }

        if (!request.headers.has('Content-Type')) {
            request = request.clone({ headers: request.headers.set('Content-Type', 'application/json') });
        }

        return next.handle(request).pipe(
            map((event: HttpEvent<any>) => {
                if (event instanceof HttpResponse) {
                    // do nothing for now
                }
                return event;
            }),
            catchError( (error: HttpErrorResponse) => {
                if (this.isValidRequestForInterceptor(request.url))
                    this.presentAlert(request, error);

                return throwError(error);
            })
        );
    }

    async presentAlert(request, error) {
        const err = JSON.parse( await error.error.text() );
        const alert = await this.alertController.create({
            header: 'Ошибка',
            subHeader: 'Что-то пошло не так!',
            message: err.Message || err.ExceptionMessage,
            buttons: ['OK']
        });

        await alert.present();
    }
}
